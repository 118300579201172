import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";

import Tracking from "./utilities/tracking";
import getPageLinks from "./utilities/getPageLinks";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Home from "./Pages/Home";
import Page from "./Pages/Page";

import PageNav from "./components/PageNav";
import Results from "./components/Results";
import Sidebar from "./components/Sidebar";
import CookieBanner from "./components/CookieBanner";
import Logo from "./components/Logo";
import ProgressBar from "./components/ProgressBar";
import AnimationContext from "./context/AnimationContext";

import "normalize.css";
import "./styles/App.scss";

import schema from "./data/schema";
import NotFound from "./Pages/NotFound";

// Run tracking
Tracking.start();

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const urlScore = urlParams.get("s");
let parsedScore;
let defaultScores = {};

if (urlScore) {
  try {
    parsedScore = JSON.parse(atob(urlScore));
  } catch (e) {
    console.error("The score in the URL is malformed");
  }
}

schema.forEach((section) => {
  defaultScores[section.id] = {};
  section.questions.forEach((question) => {
    defaultScores[section.id][question.id] = null;
  });
});

// Check if the score coming from the URL is the same shape as the default score list we just made
if (
  parsedScore &&
  JSON.stringify(Object.keys(defaultScores)) ===
    JSON.stringify(Object.keys(parsedScore))
) {
  defaultScores = parsedScore;
}

function App() {
  const [scores, setScores] = useState(defaultScores);
  const [totalScore, setTotalScore] = useState(false);
  const [encodedScore, setEncodedScore] = useState();
  const { pathname } = useLocation();
  const { animation } = useContext(AnimationContext);
  const [{ questionId, sectionUrl }, setParams] = useState({
    questionId: null,
    sectionUrl: null,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const individualScoreTotals = {};
    let absoluteTotal = 0;
    let absoluteAnswered = 0;
    let absoluteQuestionCount = 0;
    let absoluteScoreCap = 0;
    let absoluteAnsweredScoreCap = 0;

    Object.entries(scores).forEach(([sectionId, values]) => {
      const currentQuestionCount = Object.entries(values).length;
      const currentScoreCap = currentQuestionCount * 5; // This is the highest possible score for this section
      let currentTotal = 0;
      let currentAnswered = 0;
      let currentAnsweredScoreCap = 0;

      Object.entries(values).forEach(([entry, value]) => {
        if (value !== null) {
          currentTotal += parseInt(value);
          currentAnswered += 1;
          currentAnsweredScoreCap += 5;
        }
      });

      absoluteTotal += currentTotal;
      absoluteAnswered += currentAnswered;
      absoluteQuestionCount += currentQuestionCount;
      absoluteScoreCap += currentScoreCap;
      absoluteAnsweredScoreCap += currentAnsweredScoreCap;

      individualScoreTotals[sectionId] = {
        values,
        score: currentTotal,
        scoreCap: currentScoreCap,
        answeredScoreCap: currentAnsweredScoreCap,
        questionCount: currentQuestionCount,
        answered: currentAnswered,
        percent: Math.round((100 * currentTotal) / currentAnsweredScoreCap),
      };
    });

    if (absoluteAnswered > 0) {
      const encodedScores = btoa(JSON.stringify(scores));
      setEncodedScore(encodedScores);
    }

    setTotalScore({
      total: {
        score: absoluteTotal,
        scoreCap: absoluteScoreCap,
        questionCount: absoluteQuestionCount,
        answered: absoluteAnswered,
        answeredScoreCap: absoluteAnsweredScoreCap,
        percent: Math.round((100 * absoluteTotal) / absoluteAnsweredScoreCap),
      },
      individual: individualScoreTotals,
    });
  }, [scores]);
  const timeout = animation ? { enter: 600, exit: 0 } : { enter: 0, exit: 0 };
  const transitionClassNames = "page";
  const sidebar = document.querySelector(".sidebar");
  sidebar &&
    (sidebar.style.transition = animation ? "transform 0.5s ease" : "none");

  const links = getPageLinks(questionId, sectionUrl, encodedScore, pathname);
  return (
    <>
      <main>
        <TransitionGroup component="section" className="transition-container">
          <CSSTransition
            key={useLocation().pathname}
            timeout={timeout}
            classNames={transitionClassNames}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            <Switch>
              <Route path="/404">
                <NotFound />
              </Route>
              <Route path="/results">
                <Results
                  totalScore={totalScore}
                  rawScores={scores}
                  encodedScore={encodedScore}
                  onParamChange={setParams}
                />
              </Route>
              <Route path="/:sectionUrl/:questionId">
                <Page
                  scores={scores}
                  updateScore={setScores}
                  encodedScore={encodedScore}
                  totalScore={totalScore}
                  onParamChange={setParams}
                />
              </Route>
              <Route path="/:sectionUrl">
                <Page
                  scores={scores}
                  updateScore={setScores}
                  encodedScore={encodedScore}
                  totalScore={totalScore}
                  onParamChange={setParams}
                />
              </Route>
              <Route path="/">
                <Home onParamChange={setParams} />
              </Route>{" "}
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </main>
      <PageNav next={links?.link?.target} previous={links?.prevLink?.target} />
    </>
  );
}

function RouterApp() {
  const [open, setOpen] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(
    !Cookies.get()["cookie-banner-dismissed"]
  );
  useEffect(() => {
    //Enter button listener//
    const keyDownHandler = (event) => {
      switch (event.key) {
        case "Escape":
          closeSidebar();
          break;
        default:
          break;
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  });

  const dismissCookieBanner = () => {
    setShowCookieBanner(false);
    Cookies.set("cookie-banner-dismissed", true, {
      expires: 90, // 90 days from now
    });
  };

  const openSidebar = () => {
    setOpen(true);
    // Tracking [#4]
    Tracking.trackEvent({
      event: "about-menu.click.open",
    });
  };

  const closeSidebar = () => {
    setOpen(false);

    // Tracking [#5]
    Tracking.trackEvent({
      event: "about-menu.click.close",
    });
  };

  const [animation, setAnimation] = useState(() => {
    const isReduced =
      window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
      window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

    return !isReduced;
  });
  const toggleAnimation = () => {
    setAnimation(!animation);
  };
  const colorVariant = 1;

  const animationContextValue = {
    animation,
    toggleAnimation,
    colorVariant,
  };

  return (
    <>
      <div className="App-container">
        <div
          className={`App__overlay ${open && "App__overlay--visible"}`}
          onClick={closeSidebar}
        />
        <Sidebar setOpen={setOpen} open={open} />
        <Router>
          <header>
            <ProgressBar />
            <div className="header__content">
              <Logo />
              <button
                className="header__about"
                onClick={openSidebar}
                aria-label="Open the About section"
              >
                About
              </button>
            </div>
          </header>
          <AnimationContext.Provider value={animationContextValue}>
            <App />
          </AnimationContext.Provider>
        </Router>
        <CookieBanner show={showCookieBanner} onClose={dismissCookieBanner} />
      </div>
    </>
  );
}

export default RouterApp;
