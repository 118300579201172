import React from "react";
import { ReactComponent as FrogLogo } from "../../assets/logos/logo.svg";

import "./styles.scss";

const Logo = () => {
  return (
    <a href="/" className="logo__link">
      <div className="logo__container">
        <FrogLogo className="logo__frog-logo" />
        <span className="logo__text"> The Universal Score</span>
      </div>
    </a>
  );
};

export default Logo;
