import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";

import "./styles.scss";

const ExternalLinks = ({ links, inverse }) => {
  return (
    <ul className={`external-links ${inverse && 'external-links--inverse'}`}>
    {links.map((resource) => (
      <li key={resource.link}>
        <a
          className="external-links__link"
          href={resource.link}
          target="_blank"
          rel="noopener noreferrer"
          data-track-description={
            resource.trackDescription ? resource.trackDescription : ""
          }
        >
          <span>{resource.copy}</span>
          <Arrow />
        </a>
        {resource?.source && <p className="external-links__source">{resource.source}</p>}
      </li>
    ))}
  </ul>
  );
}

export default ExternalLinks;