import schema from "../data/schema";

const getProgressData = (sectionUrl, questionId) => {
  let totalSteps = 0;
  let currentStep = 0;

  schema.forEach((section) => {
    if (sectionUrl === section.meta.url && !questionId) {
      currentStep = totalSteps;
    }

    totalSteps++;

    section.questions.forEach((question) => {
      if (sectionUrl === section.meta.url && questionId === question.step) {
        currentStep = totalSteps;
      }

      totalSteps++;
    });
  });

  return [totalSteps - 1, currentStep];
};

export default getProgressData;
