import React from "react";
import { Link } from "react-router-dom";
import AnimationToggler from "../AnimationToggler";

import "./styles.scss";

const Next = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="32"
      y="-0.000244141"
      width="32"
      height="32"
      rx="5"
      // transform="rotate(90 32 -0.000244141)"
      fill="white"
    />
    <path
      d="M11 13.4998L16 18.4998L21 13.4998"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

const Previous = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="32.0002"
      width="32"
      height="32"
      rx="5"
      // transform="rotate(-90 0 32.0002)"
      fill="white"
    />
    <path
      d="M21 18.5002L16 13.5002L11 18.5002"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

const PageNav = ({ next, previous }) => {
  return (
    <footer className="page-nav">
      <div className="page-nav__content">
        <AnimationToggler />
        <div className="page-nav__links">
          <Link
            className={`page-nav__link page-nav__link--previous ${!previous && "page-nav__link--disabled"}`}
            title="previous"
            disabled={!previous}
            to={previous || '#'}
          >
            <Previous />
          </Link>
          <Link
            className={`page-nav__link page-nav__link--next ${!next && "page-nav__link--disabled"}`}
            title="next"
            disabled={!next}
            to={next || '#'}
          >
            <Next />
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default PageNav;
