import React, { useEffect } from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import Tracking from "../../utilities/tracking";
import AnimationContext from "../../context/AnimationContext";
import Anchor from "../../components/Anchor";
import HandGlobe from "../../components/HandGlobe";
import { ReactComponent as Enter } from "../../assets/images/enter.svg";

import "./styles.scss";

const Home = ({ onParamChange }) => {
  const history = useHistory();
  useEffect(() => {
    // Pageview
    Tracking.trackEvent({
      event: "pageview",
    });
    //Enter button listener//
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        history.push("/wellbeing");
      }
    };
    if (typeof onParamChange == 'function') { onParamChange({ questionId: null, sectionUrl: null }); };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [onParamChange, history]); //test

  const { animation } = useContext(AnimationContext);

  return (
    <div className="page page--home home" id="home">
      <div className="home__content">
        <div className="home__image">
          <HandGlobe animation={animation} />
        </div>
        <div className="home__left-container">
          <h1 className="home__title">The Universal Score</h1>
          <h2 className="home__subtitle">
            Find out how well you're designing for inclusion and belonging
          </h2>
          <p className="home__intro">
            Evaluate your product or service against questions on five areas of
            inclusivity. Get your final score with practical recommendations to
            help you raise your game.
          </p>
          <div className="home__cta-container">
            <Anchor target="wellbeing">Get Started</Anchor>
            <span className="home__cta">
              press <span className="home__cta-text--bold">Enter</span>{" "}
              <Enter className="home__cta-icon" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
