import React from "react";
import "./styles.scss";

const options = ["1", "2", "3", "4", "5"];

const RadioGroup = ({ name, score, onChange, positive, negative }) => {
  const labels = [negative, "", "", "", positive];

  return (
    <div className="radio-group">
      <div className="radio-group__labels">
        <p className="radio-group__label radio-group__label--negative">
          {negative || "Super low"}
        </p>
        <p className="radio-group__label radio-group__label--positive">
          {positive || "Super high"}
        </p>
      </div>
      <div className="radio-group__radios">
        {options.map((option, key) => (
          <div
            className={`radio-group__radio radio-group__radio--${option}-${key}`}
            key={option}
          >
            <input
              tabIndex={0}
              id={`${name}-${option}`}
              type="radio"
              value={option}
              name={name}
              checked={score === option}
              onChange={(e) => onChange(e.target.value)}
              aria-label={`${option} (${labels[key]})`}
            />
            <label key={option} htmlFor={`${name}-${option}`}>
              {option}
            </label>
            <p
              className={`radio-group__key-label ${
                score === option ? "radio-group__key-label-checked" : ""
              }`}
            >{`Key ${option}`}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;
