import React from "react";
import { useLocation } from "react-router-dom";

import getProgressData from "../../utilities/getProgressData";

import "./styles.scss";

const disableProgressBarUrls = ["/", "/results"];

const ProgressBar = () => {
  const { pathname } = useLocation();

  if (disableProgressBarUrls.includes(pathname)) {
    return null;
  }

  const urlRegex = /^\/([a-z0-9-]+)(\/([0-9])+)?/;
  const matches = pathname.match(urlRegex);
  const sectionUrl = matches && matches[1];
  const questionId = matches && matches[3];
  const [total, current] = getProgressData(sectionUrl, parseInt(questionId));

  const pctg = total ? Math.round((current / total) * 100) : 0;

  return (
    <div className="progressbar">
      <span
        className="progressbar__done"
        style={{ width: `${pctg}%` }}
      ></span>
    </div>
  );
};

export default ProgressBar;
