import schema from '../data/schema'

const getFirstUnansweredQuestionURL = (rawScores) => {
  if(!rawScores) {
    return null
  }

  const scoreArray = Object.keys(rawScores)
  let first = {}
  
  for (let sectionIndex in scoreArray) {
    const sectionId = scoreArray[sectionIndex]
    const sectionScores = Object.keys(rawScores[sectionId])

    if(first.sectionId && first.questionId) {
      break;
    }

    for (let questionIndex in sectionScores) {
      const questionId = sectionScores[questionIndex] 
      if(rawScores[sectionId][questionId] === null) {
        first = {
          sectionId,
          questionId, 
        }
        break;
      }
    }
  }

  if(!first.sectionId) {
    return null;
  }

  const section = schema.filter((s) => s.id === first.sectionId)[0]
  const URL = `/${section.meta.url}/${first.questionId}`

  return URL
}

export default getFirstUnansweredQuestionURL;
