import React, { useContext } from 'react';
import AnimationContext from '../../context/AnimationContext';

import "./styles.scss";

const AnimationToggler = () => {
  const { animation, toggleAnimation } = useContext(AnimationContext);

  return (
    <div className="animation-toggler" onClick={toggleAnimation}>Animation: {animation? "on" : "off"}</div>
  );
}

export default AnimationToggler;
