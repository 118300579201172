import schema from '../data/schema';

const getTiles = (scores) => {
  if(!scores) {
    return null
  }
  const tiles = scores.individual;
  const arranged = []

  schema.forEach((section) => {
    tiles[section.id].meta = {
      ...section.meta
    }
    tiles[section.id].id = section.id

    let grade;

    if(tiles[section.id].percent >= 90) {
      grade = 'green'
    } else if (tiles[section.id].percent >= 50) {
      grade = 'amber'
    } else {
      grade = 'red'
    }

    arranged.push({
      ...tiles[section.id],
      grade
    })
  })

  return arranged
} 

export default getTiles;
