import frogbg from "../../assets/images/frogbg.webp";
import frogbgStatic from "../../assets/images/frogbg-static.jpg";
import { ReactComponent as GlobeHandMask } from "../../assets/images/globe-mask-all.svg";

import "./styles.scss";

const HandGlobe = ({ animation, color }) => {
  return (
    <div className="handglobe" style={{ color: color || "#f4f4f4" }}>
      <GlobeHandMask className="handglobe__fg" />
      <img
        src={animation === true ? frogbg : frogbgStatic}
        alt="swirly purple gradient"
        className="handglobe__bg"
      />
    </div>
  );
};

export default HandGlobe;
