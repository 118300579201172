import React from "react";
import './styles.scss';

import RadioGroup from "../RadioGroup";

const Question = ({ question, score, changeScore, link, name }) => {
  return (
    <>
      <div className="question">
        <h1 className="page-title question__title">{question.question}</h1>
        <p className="question__detail">{question.detail}</p>
        <RadioGroup
          name={question.id}
          score={score}
          onChange={(value) => changeScore(value)}
          positive={question.positive}
          negative={question.negative}
        />
      </div>
      {/* <Anchor
        target={link.target}
      >
        {link.title}
      </Anchor> */}
    </>
  )
}

export default Question
