const schema = [
  {
    id: "wellbeing",
    color: "#DDE0FC",
    meta: {
      step: 1,
      title:
        "Let's start by considering how your product, service or journey impacts the mental wellbeing of its users.",
      cta: "OK",
      name: "Mental wellbeing",
      url: "wellbeing",
      next: "belonging", // this is the key in this object for the
      prev: "home",
      result: {
        red: "There's a lot to improve here. Try going back to one of the questions and focus on getting that right first.",
        amber:
          "Try revisiting your design principles to increase the consideration for people struggling with anxiety or impulsive behaviours.",
        green:
          "If you want to really up your game, bring in a mental wellbeing expert to  identify areas where you can really make a difference.",
      },
      resources: [
        {
          copy: "Understanding anxiety and building empathy",
          source: "Vildana Lojo Babić",
          link: "https://uxdesign.cc/understanding-anxiety-and-building-empathy-9f594a6c9bc3",
          trackDescription: "mental wellbeing - Understanding anxiety and building empathy",
        },
        {
          copy: "Designing for anxiety",
          source: "Uxcel",
          link: "https://app.uxcel.com/courses/design-accessibility/designing-for-anxiety-243",
          trackDescription: "mental wellbeing - designing for anxiety",
        },
      ],
    },
    questions: [
      {
        id: 1,
        step: 1,
        url: "1",
        positive: "Easy",
        negative: "Difficult",
        tracking_id: "how_easy_fix_mistake",
        question: "How easy is it for people to fix mistakes?",
        detail:
          "Giving people the option to review information, update or cancel can reduce their anxiety of doing something wrong.",
      },
      {
        id: 2,
        step: 2,
        url: "2",
        positive: "Definitely",
        negative: "Not at all",
        tracking_id: "clear_expectations_at_each_stage",
        question:
          "Is it clear what's expected of people at each stage of the journey?",
        detail:
          "Clear instructions and visual cues, like appropriate button states, can help people understand what's expected of them.",
      },
      {
        id: 3,
        step: 3,
        url: "3",
        positive: "Easy",
        negative: "Difficult",
        tracking_id: "how_easy_see_journey_progress",
        question:
          "How easy is it for people to see where they are in the journey?",
        detail:
          "Surfacing progress and communicating next steps helps people feel more in control of the journey.",
      },
      {
        id: 4,
        step: 4,
        url: "4",
        positive: "Definitely",
        negative: "Not at all",
        tracking_id: "decisions_without_unnecessary_pressure",
        question: "Can your users make decisions without unnecessary pressure?",
        detail:
          "Playing on scarcity, urgency, and fear of a missed opportunity creates unnecessary pressure. This can increase anxiety and push people to make decisions that might not be in their best interests.",
      },
    ],
  },
  {
    id: "belonging",
    color: "#FFE1E2",
    meta: {
      step: 2,
      title:
        "Great start. Now let's think about belonging and how people connect to your product, service or journey.",
      cta: "OK",
      name: "Belonging",
      url: "belonging",
      next: "physicalNeeds",
      prev: "wellbeing",
      result: {
        red: "To start considering belonging, invite a more diverse group of participants to take part in your design process.",
        amber:
          "Think about how you can go beyond 'good enough' to offer a truly representative experience.",
        green:
          "Can you push yourselves further, what KPIs do you have that will really embed this kind of thinking in your product?",
      },
      resources: [
        {
          copy: "Why inclusive product design and messaging",
          source: "Forbes",
          link: "https://www.forbes.com/sites/michellegreenwald/2020/06/01/meaningful-authentic-approaches-to-product-and-service-inclusivity/",
          trackDescription:
            "belonging - inclusive product design and messaging matters",
        },
        {
          copy: "Inclusive brand identity",
          source: "Forbes",
          link: "https://www.forbes.com/sites/pragyaagarwaleurope/2019/08/30/inclusive-brand-identity-and-product-design-can-be-done-right/",
          trackDescription:
            "belonging - inclusive brand identity and product design",
        },
      ],
    },
    questions: [
      {
        id: 1,
        step: 1,
        url: "1",
        positive: "Very much",
        negative: "Not at all",
        tracking_id: "imagery_represent_all_target_users",
        question: "How much does your imagery represent all your target users?",
        detail:
          "For example, people are more likely to feel a stronger emotional bond with illustration and photography that celebrates and reflects diversity.",
      },
      {
        id: 2,
        step: 2,
        url: "2",
        positive: "Very confident",
        negative: "Not at all",
        tracking_id: "confident_is_language_clear_for_all",
        question:
          "Are you confident that the language you've used will be clear to all?",
        detail:
          "Watch out for pop-culture references, idioms, and humour as they can be culturally specific and may not be understood by all.",
      },
      {
        id: 3,
        step: 3,
        url: "3",
        positive: "Definitely",
        negative: "Not at all",
        tracking_id: "considered_varied_cultures_and_beliefs",
        question: "Have you considered varied cultures and beliefs?",
        detail:
          "Understand the value that different cultures place on colours and symbols and whether metaphors or phrases could be lost in translation. Consider localising the UX and UI to appeal to different cultures.",
      },
      {
        id: 4,
        step: 4,
        url: "4",
        positive: "Definitely",
        negative: "Not really",
        tracking_id: "sensitive_when_asking_for_information",
        question:
          "Are you being sensitive when asking for people's information?",
        detail:
          "Allowing people to choose preferred pronouns and making it clear how their data will be used are positive design choices.",
      },
    ],
  },
  {
    id: "physicalNeeds",
    color: "#B5F4EF",
    meta: {
      step: 3,
      title:
        "Nearly halfway through! Let's look at how your product, service or journey meets your users' physical needs.",
      cta: "Continue",
      name: "Physical needs",
      url: "physical-needs",
      next: "neurodiversity",
      prev: "belonging",
      result: {
        red: "There are really clear accessibility guidelines in place to support teams who want to design more accessible products and services. Try the A11Y Project Checklist to check your compliance.",
        amber:
          "Get familiar with the latest assistive technologies and try to bring them into your design process early on.",
        green:
          "Have you thought about making your accessibility efforts more visible? You're doing really well, and the more we talk about this stuff, the more people take notice.",
      },
      resources: [
        {
          copy: "The A11Y Project Checklist",
          source: "The Accessibility Project",
          link: "https://www.a11yproject.com/checklist/",
          trackDescription: "physical needs - the a11y project checklist",
        },
        {
          copy: "Inclusive Toolkit Manual (PDF)",
          source: "Microsoft",
          link: "https://download.microsoft.com/download/b/0/d/b0d4bf87-09ce-4417-8f28-d60703d672ed/inclusive_toolkit_manual_final.pdf",
          trackDescription: "physical needs - microsoft inclusive toolkit",
        },
      ],
    },
    questions: [
      {
        id: 1,
        step: 1,
        url: "1",
        positive: "Definitely",
        negative: "Not really",
        tracking_id: "can_visual_needs_use_and_enjoy",
        question:
          "Can people with visual impairments access, use, and enjoy the experience?",
        detail:
          "Sight is a spectrum: people can be anywhere from sighted or partially sighted to blind. Also consider temporary impairments, like someone wearing sunglasses. You can include more people by making digital products screen-reader friendly, using sufficient colour contrast, or using braille in the physical world.",
      },
      {
        id: 2,
        step: 2,
        url: "2",
        positive: "Definitely",
        negative: "Not at all",
        tracking_id: "can_hearing_needs_use_and_enjoy",
        question:
          "Can people with hearing impairments access, use, and enjoy the experience?",
        detail:
          "Hearing is also a spectrum. Many people who can usually hear clearly might not be able to hear in some contexts. Some of those contexts might be temporary, like being on a noisy train. Providing transcripts or subtitles are inclusive design choices.",
      },
      {
        id: 3,
        step: 3,
        url: "3",
        positive: "Definitely",
        negative: "Not at all",
        tracking_id: "can_physical_needs_use_and_enjoy",
        question:
          "Can people with physical impairments access, use, and enjoy the experience?",
        detail:
          "To accommodate people with physical impairments, consider practices like keyboard shortcuts and tabbing, or wheelchair access in the physical world. Remember that physical impairments aren't always permanent. What if someone is holding a child?",
      },
      {
        id: 4,
        step: 4,
        url: "4",
        positive: "Definitely",
        negative: "Not at all",
        tracking_id: "easily_access_digital_support",
        question: "Can people easily access digital support?",
        detail:
          "Sometimes people need help. Making support obvious, clear, and accessible for screen readers makes it easier to access for those in need.",
      },
    ],
  },
  {
    id: "neurodiversity",
    color: "#F5E4CB",
    meta: {
      step: 4,
      title:
        "Now, some questions about neurodiversity and how your product, service or journey addresses the different ways people process information. ",
      cta: "OK",
      name: "Neurodiversity",
      url: "neurodiversity",
      next: "people",
      prev: "physicalNeeds",
      result: {
        red: "It's estimated that around 15% of the UK population are neurodivergent. By ignoring their needs, you're alienating at least one in seven potential customers!",
        amber:
          "Not bad, but consider including more neurodivergent people in testing so you can build confidence that you're meeting their needs.",
        green:
          "It's great you're designing for people with different cognitive needs first. It means the results will be more enjoyable for everyone.",
      },
      resources: [
        {
          copy: "How to be inclusive to neurodiverse people (video)",
          source: "Rachel Morgan-Trimmer",
          link: "https://www.youtube.com/watch?v=scKW7gwRZ9Q",
          trackDescription: "neurodiversity - How to be inclusive to neurodiverse people",
        },
        {
          copy: "Designing for guidance (PDF)",
          source: "Microsoft",
          link: "https://inclusive.microsoft.design/tools-and-activities/CreatingForGuidance.pdf",
          trackDescription: "neurodiversity - designing for guidance",
        },
        {
          copy: "The Great Digital Divide",
          source: "Capgemini",
          link: "https://www.capgemini.com/news/the-great-digital-divide/",
          trackDescription: "neurodiversity - the great digital divide",
        },
      ],
    },
    questions: [
      {
        id: 1,
        step: 1,
        url: "1",
        positive: "Easy",
        negative: "Difficult",
        tracking_id: "how_easy_short_attention_span_complete_task",
        question:
          "How easy is it for people with shorter attention spans to complete tasks?",
        detail:
          "Clear instructions and steps to minimise screen time, or alternatives to passwords like biometrics, can help people with shorter attention spans.",
      },
      {
        id: 2,
        step: 2,
        url: "2",
        positive: "Easy",
        negative: "Difficult",
        tracking_id: "easy_to_avoid_impulsive_decisions_and_mistakes",
        question:
          "Is it easy for people to avoid making impulsive decisions and mistakes?",
        detail:
          "The right level of friction – like showing confirmation steps, or allowing people to review information before submitting – can help to manage impulsive behaviours and errors.",
      },
      {
        id: 3,
        step: 3,
        url: "3",
        positive: "Yes, easily",
        negative: "Not at all",
        tracking_id: "pick_ip_where_they_left_off_later",
        question: "Can people pick up where they left off later on?",
        detail:
          "Users often need to leave a journey before completing it. The ability to save progress helps people to complete the journey more quickly and easily.",
      },
      {
        id: 4,
        step: 4,
        url: "4",
        positive: "Yes, easily",
        negative: "Not at all",
        tracking_id: "focus_on_task_with_minimal_distractions",
        question:
          "Can people focus on the task at hand with minimal distractions?",
        detail:
          "Keeping copy clear and concise, and storing additional information in accordions or tooltips, allows users to focus on what matters the most.",
      },
    ],
  },
  {
    id: "people",
    color: "#EAEAEA",
    meta: {
      step: 5,
      title:
        "Great, onto the last set of questions. Let's look at how you put people first while considering wider business goals.",
      cta: "Let's go",
      name: "People first",
      url: "people-first",
      next: "results",
      prev: "neurodiversity",
      result: {
        red: "You might have lost sight of your customers' needs. Try setting up some face-to-face sessions with real users to understand how they experience your product, service or journey.",
        amber:
          "Consider mapping out a broad set of possible customer contexts and use Cards for Humanity to build empathy for a range of personas in different situations.",
        green:
          "By putting people first, you're putting your customers in a better emotional and financial position. It means they're more likely to stay loyal to a brand that isn't exploiting them.",
      },
      resources: [
        {
          copy: "Applying the lens of vulnerability to design",
          source: "CACI Digital Experience",
          link: "https://www.cyber-duck.co.uk/insights/applying-the-lens-of-vulnerability-to-design",
          trackDescription:
            "putting people first - consumer vulnerability challenges and solutions",
        },
        {
          copy: "Consumer vulnerability",
          source: "Gov UK",
          link: "https://www.gov.uk/government/publications/consumer-vulnerability-challenges-and-potential-solutions/consumer-vulnerability-challenges-and-potential-solutions",
          trackDescription:
            "putting people first - consumer vulnerability challenges and solutions",
        },
        {
          copy: "Cards for Humanity",
          source: "frog",
          link: "https://cardsforhumanity.frog.co/",
          trackDescription: "putting people first - cards for humanity",
        },
      ],
    },
    questions: [
      {
        id: 1,
        step: 1,
        url: "1",
        positive: "Definitely",
        negative: "Not at all",
        tracking_id: "considered_impact_of_life_changes_on_customers",
        question:
          "Have you considered the impact of life changes on your customers?",
        detail:
          "Sudden loss of earnings, bereavement, or other changes in circumstance can make people financially or emotionally vulnerable. Flexible terms and proactive support can help people get back on their feet.",
      },
      {
        id: 2,
        step: 2,
        url: "2",
        positive: "Definitely",
        negative: "Not at all",
        tracking_id: "encouraged_to_make_decisions_in_best_interest",
        question:
          "Are your users encouraged to make decisions that are in their best interests?",
        detail:
          "Clear reminders, helpful warnings, and easy-to-understand T&Cs will help people decide what's best for them.",
      },
      {
        id: 3,
        step: 3,
        url: "3",
        positive: "Definitely",
        negative: "Not at all",
        tracking_id: "people_know_where_they_stand",
        question: "Do your users know where they stand?",
        detail:
          "Sending timely payment reminders, allowing easy subscription updates, and surfacing confirmation steps, can help people feel more in control and clear about commitments.",
      },
      {
        id: 4,
        step: 4,
        url: "4",
        positive: "Definitely",
        negative: "Not at all",
        tracking_id: "enough_information_to_make_informed_decisions",
        question:
          "Is there enough information for your users to make informed decisions?",
        detail:
          "Educating people on what to expect and using clear wording is good practice. Balance simplicity with transparency so that people know exactly what they're buying. Visual aids can also help.",
      },
    ],
  },
];

export default schema;
