import React, { useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";

import Anchor from "../../components/Anchor";
import Question from "../../components/Question";
import NotFound from '../NotFound';
import { ReactComponent as Enter } from "../../assets/images/enter.svg";
import { ReactComponent as MentalWellBeing } from "../../assets/images/wellbeing.svg";
import { ReactComponent as Neurodiversity } from "../../assets/images/neurodiversity.svg";
import { ReactComponent as People } from "../../assets/images/people.svg";
import { ReactComponent as Physical } from "../../assets/images/physicalNeeds.svg";
import { ReactComponent as Belonging } from "../../assets/images/belonging.svg";

import schema from "../../data/schema";
import Tracking from "../../utilities/tracking";

import "./styles.scss";
import getPageLinks from "../../utilities/getPageLinks";

const Page = ({ scores, updateScore, encodedScore, onParamChange }) => {
  const { questionId: questionIdString, sectionUrl } = useParams();
  const questionId = parseInt(questionIdString);
  const history = useHistory();
  const section = schema.find((s) => s.meta.url === sectionUrl);
  const { link } = getPageLinks(questionId, sectionUrl, encodedScore);
  const nextUrl = link.target;

  const getQuestionNum = (step, questionId) => {
    let num = 0;
    for (let i = 1; i < Math.min(step, schema.length); i++) {
      num += schema[i - 1].questions.length;
    }
  
    return num + questionId;
  };  

  useEffect(() => {
    // Pageview
    Tracking.trackEvent({
      event: "pageview",
    });
    if(typeof onParamChange === 'function') { onParamChange({ questionId, sectionUrl }); }
  }, [questionId, sectionUrl, onParamChange]);

  useEffect(() => {
    //Enter button listener//
    const keyDownHandler = (event) => {
      switch (event.key) {
        case "Enter":
          history.push(nextUrl);
          break;
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
          if (question) changeScore(event.key, questionId, section);
          break;
        default:
          break;
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  });

  if (!section) {
    return <NotFound />;
  }

  const score = scores[section.id];
  const {
    meta: { title, name, step },
    questions,
  } = section;

  const questionIndex = questions.findIndex((q) => q.id === questionId);
  const question = questions[questionIndex];
  if (questionId && questionId && !(questionIndex || question)) {
    return <NotFound />;
  }

  const changeScore = (newScore, key, skip) => {
    updateScore({
      ...scores,
      [section.id]: {
        ...score,
        [key]: newScore,
      },
    });

    // If the last question in the section was skipped log event
    if (key === 4 && skip) logSectionScore({ ...score, [key]: newScore });
  };

  const logSectionScore = (scores) => {
    // Calculate score
    let currentTotal = 0,
      currentAnsweredScoreCap = 0;
    Object.entries(scores).forEach(([entry, value]) => {
      if (value !== null) {
        currentTotal += parseInt(value);
        currentAnsweredScoreCap += 5;
      }
    });

    // Tracking [#2]
    Tracking.trackEvent({
      event: "evaluation.click.last-question",
      section: section.id,
      score: `${currentTotal}/${currentAnsweredScoreCap}`,
    });
  };

  const logQuestionEvent = (section, questionId, answer) => {
    // Tracking [#1]
    Tracking.trackEvent({
      event: "evaluation.click.question-answered",
      section: section,
      question: question.tracking_id,
      answer: answer === "skip" ? "skip" : answer,
    });
  };

  const logSectionQuestionAnswer = () => {
    // Only run on question pages
    if (questionId) {
      logQuestionEvent(section.id, questionId, scores[section.id][questionId]);
    }

    // Log section score if the the last question was not skipped (skip loggin is done in changeScore())
    if (questionId === 4) logSectionScore(scores[section.id]);
  };

  const clearQuestion = () => {
    changeScore(null, questionId, true);

    logQuestionEvent(section.id, questionId, "skip");
  };

  const getSectionIcon = (section) => {
    switch (section.id) {
      case "wellbeing":
        return <MentalWellBeing className="page__section-icon" />;
      case "belonging":
        return <Belonging className="page__section-icon" />;
      case "physicalNeeds":
        return <Physical className="page__section-icon" />;
      case "neurodiversity":
        return <Neurodiversity className="page__section-icon" />;
      case "people":
        return <People className="page__section-icon" />;
      default:
        break;
    }
  };
  let questionNum;

  if (question) {
    questionNum = getQuestionNum(step, questionId);
  }

  return (
    <div
      className={`page page${question ? "--questionaire" : "--interstitial"}`}
      id={section.id}
    >
      <div className="page__content">
        {question ? (
          <>
            <p className="page__section-name">
              <strong>Q{questionNum}</strong> | {name}
            </p>
            <Question
              {...{
                section,
                question,
                score: score[questionId],
                changeScore: (value) => changeScore(value, questionId),
              }}
            />
          </>
        ) : (
          <>
            <p className="page__section-name">
              <strong>Section {step}</strong> | {name}
            </p>
            <h1 className="page-title"><span>{title}</span></h1>
          </>
        )}
        <div className="page__Anchor-container">
          <Anchor
            target={question && !score[questionId] ? undefined : link.target}
            disabled={question && !score[questionId]}
            onClick={logSectionQuestionAnswer}
          >
            {link.title}
          </Anchor>

          <span className="home__cta">
            press <span className="home__cta-text--bold">Enter</span>{" "}
            <Enter className="home__cta-icon" />
          </span>
        </div>

        {question && (
          <div className="page__skip">
            <Link
              to={link.target}
              onClick={clearQuestion}
              className="page__skip-link"
            >
              {score[questionId] ? "Clear selection and skip" : "Skip"}
            </Link>
          </div>
        )}
      </div>
      {!question && getSectionIcon(section)}
    </div>
  );
};

export default Page;
