import React from 'react'
import { useTransition, animated } from 'react-spring';

import { ReactComponent as IconClose } from '../../assets/icons/close.svg'

import './styles.scss'

/* eslint-disable react/jsx-no-target-blank */

const CookieBanner = ({ onClose, show }) => {
  const expand = useTransition(show ? [1] : [], null, {
    from: {
      height: '58px',
      transform: 'translateY(0px)'
    },
    enter: {
      height: '58px',
      transform: 'translateY(0px)'
    },
    leave: {
      height: '0',
      transform: 'translateY(58px)'
    },
  });
  return expand.map(({ item, props, key }) => item &&
    <animated.div
      role="complementary"
      className="cookie-banner"
      key={key}
      style={props}
    >
      <div className="cookie-banner__content" data-testid="cookie-consent-banner">
        <div className="cookie-banner__links">
          <a
            href="https://www.capgemini.com/cookie-policy/"
            target="_blank"
            rel="noopener"
            data-testid="cookie-policy-link"
          >
            See our cookie policy
          </a>
        </div>
        <button
          className="cookie-banner__close"
          onClick={onClose}
          aria-label="close cookie notice"
          data-testid="dismiss-cookie-banner-button"
        >
          <IconClose />
        </button>
      </div>
    </animated.div>
  )
}

export default CookieBanner
