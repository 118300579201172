const copy = [
  {
    heading: "Awesome, you're onto a game changer.",
    copy: "Your score is higher than average. If you can protect your design decisions through the prioritisation process, your product or service will reach new people and have a positive impact on society. See a breakdown of your score below with tips to keep raising your game."
  },
  {
    heading: "You're on track to be more inclusive",
    copy: "Your score is above average. But there's still room for improvement. Find a breakdown of your score below with suggestions on how to really change the world."
  },
  {
    heading: "Not bad, but can you level up?",
    copy: "Your score is slightly below average. Find a breakdown of your score below, and check out our suggestions for how to raise your game. Small changes can make a big difference in how inclusive our products and services are."
  },
  {
    heading: "You're leaving people behind.",
    copy: "Your score is low. There are a lot of improvements to make before you can say you've designed for everyone. Find a breakdown of your score below and check out our suggestions to help you make more inclusive design decisions."
  }
]

 const getSummary = (rawScore) => {
  const score = parseInt(rawScore)
  if (score >= 90) {
    return copy[0]
  } else if (score >= 70 && score < 90) {
    return copy[1]
  } else if (score >= 50 && score < 70) {
    return copy[2]
  } else {
    return copy[3]
  }
}

export default getSummary
