import schema from "../data/schema";

const getPageLinks = (questionId, sectionUrl, encodedScore, pathname) => {
  let link = {
    target: `/${schema[0].meta.url}`,
    title: "Next question",
  };
  let prevLink;

  const section = schema.find((s) => s.meta.url === sectionUrl);

  if (section && section.meta) {
    const {
      meta: { url, next, prev, cta },
      questions,
    } = section;
    const questionIndex = questions.findIndex((q) => q.id === questionId);
    const question = questions[questionIndex];
    const nextSchema = schema.find((s) => s.id === next);
    const nextUrl = nextSchema?.meta?.url || `results?s=${encodedScore}`;
    const prevSchema = schema.find((s) => s.id === prev);
    const prevUrl = prevSchema?.meta?.url || "/";

    if (question) {
      link = !questions[questionIndex + 1]
        ? {
            target: `/${nextUrl}`,
            title: "Next",
          }
        : {
            target: `/${url}/${questionId + 1}`,
            title: "Next",
          };
    } else {
      link = {
        target: `/${url}/1`,
        title: cta,
      };
    }

    if (question) {
      prevLink =
        questionId === 1
          ? {
              target: `/${url}`,
              title: "Previous section",
            }
          : {
              target: `/${url}/${questionId - 1}`,
              title: "Next question",
            };
    } else {
      prevLink = {
        target: prevUrl === "/" ? prevUrl : `/${prevUrl}/4`,
        title: "Previous questions",
      };
    }
  }
  if (pathname?.includes('results')) {
    prevLink = {
      target: `/${schema[schema.length - 1].meta.url}/${
        schema[schema.length - 1].questions[
          schema[schema.length - 1].questions.length - 1
        ].id
      }`,
    };
    link = null
  };

  return {
    link,
    prevLink,
  };
};

export default getPageLinks;
