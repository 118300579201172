import { useContext } from "react";
import { ReactComponent as HandNoGlobe } from "../../assets/images/hand-no-globe.svg";
import bg from "../../assets/images/frogbg-static.jpg";
import bganim from "../../assets/images/frogbg.webp";
import AnimationContext from "../../context/AnimationContext";

import "./styles.scss";

const NotFound = () => {
  const { animation } = useContext(AnimationContext);
  return (
    <section className="not-found">
      <div className="not-found__content">
        <div
          className={`not-found__container ${
            animation && "not-found__container--animate"
          }`}
        >
          <h1
            className="not-found__title"
            style={{ backgroundImage: `url(${animation ? bganim : bg})` }}
          >
            404
          </h1>
        </div>
        <HandNoGlobe />
      </div>
    </section>
  );
};

export default NotFound;
