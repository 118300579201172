import React from "react";

import Tracking from "../../utilities/tracking";

import IconArrow from "../../assets/icons/arrow.svg";
import IconClose from "../../assets/icons/menu-close.svg";
import frogLogo from "../../assets/logos/logo.svg";
import Facebook from "../../assets/icons/facebook.svg";
import Twitter from "../../assets/icons/twitter.svg";
import Linkedin from "../../assets/icons/linkedin.svg";
import Instagram from "../../assets/icons/instagram.svg";

import "./styles.scss";

/* eslint-disable react/jsx-no-target-blank */

const Sidebar = ({ open, setOpen, ...props }) => {
  const isHidden = open ? false : true;
  const tabIndex = open ? 0 : -1;

  const closeSidebar = () => {
    setOpen(false);

    // Tracking [#5]
    Tracking.trackEvent({
      event: "about-menu.click.close",
    });
  };

  return (
    <aside
      open={open}
      aria-hidden={isHidden}
      data-testid="about-sidebar"
      className={`sidebar ${open && "sidebar--open"}`}
    >
      <div>
        <button
          className="sidebar__close "
          aria-label="Close the About sidebar"
          onClick={closeSidebar}
          tabIndex={tabIndex}
        >
          <img src={IconClose} aria-hidden="true" alt="" />
        </button>
        <div className="sidebar__section">
          <h2 data-testid="sidebar-what-is-universal-score-title">
            What is The Universal Score?
          </h2>
          <p data-testid="sidebar-what-is-universal-score-text">
            Universal Score is an inclusion and belonging evaluation framework,
            created by frog. It helps you think critically about how inclusive
            your work is, so you can design or build a better experience that
            works for everyone.
          </p>
        </div>
        <div className="sidebar__section">
          <h2>How to use</h2>
          <ol className="sidebar__instructions">
            <li>Choose a product, service or journey to test.</li>
            <li>
              Answer a series of questions across 5 areas of inclusivity:
              belonging, mental wellbeing, physical needs, neurodiversity, and
              putting people first. You can skip any questions that aren't
              relevant or go back at the end. At the end your final Universal
              Score out of 100 is revealed, with practical recommendations on
              how to improve it. We'd recommend running The Universal Score as a
              workshop with your team. Make sure to capture any insights or
              actions as you go.
            </li>
            <li>
              At the end your final Universal Score out of 100 is revealed, with
              practical recommendations on how to improve it.
            </li>
          </ol>
          <p data-testid="sidebar-how-to-play-text">
            We'd recommend running The Universal Score as a workshop with your
            team. Make sure to capture any insights or actions as you go.
          </p>
        </div>
        <div className="sidebar__section">
          <h2 data-testid="sidebar-idean-title">We're frog</h2>
          <p>
            <b>
              frog is the reinvention and experience partner for the eco&#8209;digital era.
            </b>
          </p>
          <p>
            Together with our clients, we innovate with purpose, using strategy, creativity, tech, data and AI to advance people and planet.
          </p>
          <p data-testid="sidebar-idean-text">
            We're also the creators of{" "}
            <a
              href="https://cardsforhumanity.frog.co"
              target="_blank"
              rel="noopener"
              data-track-category="About-Menu"
              data-track-description="cards-for-humanity"
            >
              Cards&nbsp;for&nbsp;Humanity
            </a>
            , another inclusivity tool.
          </p>
          <ul className="sidebar__link-list">
            <li>
              <a
                className="sidebar__cta"
                href="https://www.frog.co/careers"
                target="_blank"
                rel="noopener"
                tabIndex={tabIndex}
                data-testid="sidebar-idean-talk-to-us-link"
                data-track-category="About-Menu"
                data-track-description="idean-careers"
              >
                <span
                  data-track-category="About-Menu"
                  data-track-description="idean-careers"
                >
                  Check our careers page to join our team
                </span>
                <img src={IconArrow} alt="" aria-hidden="true" />
              </a>
            </li>
          </ul>
        </div>
        <div className="sidebar__section">
          <h2 data-testid="sidebar-feedback-title">Tell us your feedback</h2>
          <p data-testid="sidebar-feedback-text">
            How are you using The Universal Score? We want this site to be
            useful for as many people as possible. Please{" "}
            <a
              href="mailto:cardsforhumanity&#64;frog.co"
              tabIndex={tabIndex}
              data-testid="sidebar-feedback-link"
              data-track-category="About-Menu"
              data-track-description="drop-us-a-note"
              target="_blank"
            >
              drop us a note
            </a>{" "}
            if you have any feedback or would like help running a workshop with
            The Universal Score.
          </p>
        </div>

        <div className="sidebar__section">
          <div className="sidebar__idean-links">
            <a
              className="sidebar__idean-logo"
              href="https://www.frog.co/"
              target="_blank"
              rel="noopener"
              data-testid="sidebar-idean-logo-link"
              data-track-category="About-Menu"
              data-track-description="idean-logo"
            >
              <img
                src={frogLogo}
                alt="frog website"
                data-testid="sidebar-idean-logo"
                loading="lazy"
              />
            </a>
            <a
              className="sidebar__social-icon"
              href="https://www.facebook.com/frogdesigninc"
              target="_blank"
              rel="noopener noreferrer"
              data-testid="sidebar-facebook-link"
              data-track-category="About-Menu"
              data-track-description="social-facebook"
            >
              <img
                src={Facebook}
                alt="frog Facebook"
                data-testid="sidebar-facebook-icon"
              />
            </a>
            <a
              className="sidebar__social-icon"
              href="https://twitter.com/frogdesign"
              target="_blank"
              rel="noopener noreferrer"
              data-testid="sidebar-twitter-link"
              data-track-category="About-Menu"
              data-track-description="social-twitter"
            >
              <img
                src={Twitter}
                alt="frog Twitter"
                data-testid="sidebar-twitter-icon"
              />
            </a>
            <a
              className="sidebar__social-icon"
              href="https://www.linkedin.com/company/frogdesign/"
              target="_blank"
              rel="noopener noreferrer"
              data-testid="sidebar-linkedin-link"
              data-track-category="About-Menu"
              data-track-description="social-linkedin"
            >
              <img
                src={Linkedin}
                alt="frog LinkedIn"
                data-testid="sidebar-linkedin-icon"
              />
            </a>
            <a
              className="sidebar__social-icon"
              href="https://www.instagram.com/frog_design/"
              target="_blank"
              rel="noopener noreferrer"
              data-testid="sidebar-insta-link"
              data-track-category="About-Menu"
              data-track-description="social-instagram"
            >
              <img
                src={Instagram}
                alt="frog Instagram"
                data-testid="sidebar-insta-icon"
              />
            </a>
          </div>

          <ul>
            <li>
              <a
                className="sidebar__footer-links"
                href="https://www.capgemini.com/privacy-notice"
                target="_blank"
                rel="noopener"
                tabIndex={tabIndex}
                data-testid="sidebar-privacy-link"
                data-track-category="About-Menu"
                data-track-description="footer-privacy"
              >
                Privacy
              </a>
            </li>
            <li>
              <a
                className="sidebar__footer-links"
                href="https://www.capgemini.com/terms-of-use"
                target="_blank"
                rel="noopener"
                tabIndex={tabIndex}
                data-testid="sidebar-terms-link"
                data-track-category="About-Menu"
                data-track-description="footer-terms"
              >
                Terms of Use
              </a>
            </li>
            <li>
              <p className="sidebar__copyright" data-testid="sidebar-copyright">
                &copy; {new Date().getFullYear()} frog, a Capgemini company. All
                rights reserved.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
