import TagManager from 'react-gtm-module'


const Tracking = {
    
  // Initialise datalayer
  start: (dataLayer) => {

    let configGTM = {
      gtmId: process.env.REACT_APP_GTM_TRACKING_ID
    };

    // GTM development env
    if (process.env.NODE_ENV !== 'production') {
      configGTM.auth = process.env.REACT_APP_GTM_ENV_AUTH
      configGTM.preview = process.env.REACT_APP_GTM_ENV
    }

    // Google Tag Manager init
    TagManager.initialize(configGTM)
  },

  // push data to the data layer
  trackEvent: (event) => {

    TagManager.dataLayer({
      dataLayer: event
    });
  },

}
export default Tracking
