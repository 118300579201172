import React from "react";
import { Link } from "react-router-dom";

import './styles.scss'

const Anchor = ({ target, title, flipped, indented, children, disabled, ...rest }) => {
  return ((<div className="anchor">
    <Link
      to={target}
      disabled={disabled}
      className={`next-link ${indented && "next-link--indented"} ${disabled && "next-link--disabled"}`}
      {...rest}
    >
      {children}
    </Link>
  </div>))
};

export default Anchor;
